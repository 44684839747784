@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    overflow-x: hidden;
}

.bg-gray {
    background-color: #F2F3F3;
}

.bg-white-accordion {
    background-color: white !important;
}

.accordion__button {
    background-color: white !important;
}

.accordion__panel {
    background-color: white !important;
}

.min-h-search {
    min-height: calc(100vh);
}

input::-webkit-calendar-picker-indicator {
    display: none;
}

.min-height-22{
    min-height: 22px;
}

.color-dark-blue {
    background-color: #5398C9;
}

.image-height {
    height: 600px;
}

.color-navbar-dark-blue {
    color: #5398C9;
}

.width-card {
    width: 360px;
}

.color-icon-dark-blue {
    width: 18px;
    color: #5398C9;
}

.search-bar-container {
    bottom: -140px;
}

.search-bar-container > .search-bar {
    min-height: 280px;
}

.benefit-container {
    margin-top: 140px;
}